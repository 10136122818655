define("ember-buffered-proxy/mixin", ["exports", "ember-buffered-proxy/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    meta
  } = Ember; // eslint-disable-line ember/new-module-imports

  const hasOwnProp = Object.prototype.hasOwnProperty;

  function monkeyPatchedNotifyPropertyChange(context, key) {
    if (true && true) {
      let content = Ember.get(context, 'content');
      Ember.notifyPropertyChange(content, key);
    } else {
      Ember.notifyPropertyChange(context, key);
    }
  }

  var _default = Ember.Mixin.create({
    buffer: null,
    hasBufferedChanges: false,
    hasChanges: Ember.computed.readOnly('hasBufferedChanges'),
    applyChanges: (0, _helpers.aliasMethod)('applyBufferedChanges'),
    discardChanges: (0, _helpers.aliasMethod)('discardBufferedChanges'),

    init() {
      this.initializeBuffer();
      Ember.set(this, 'hasBufferedChanges', false);

      this._super(...arguments);
    },

    initializeBuffer(onlyTheseKeys) {
      if (Ember.isArray(onlyTheseKeys) && !(0, _helpers.empty)(onlyTheseKeys)) {
        onlyTheseKeys.forEach(key => delete this.buffer[key]);
      } else {
        Ember.set(this, 'buffer', Object.create(null));
      }
    },

    unknownProperty(key) {
      const buffer = Ember.get(this, 'buffer');
      return hasOwnProp.call(buffer, key) ? buffer[key] : this._super(key);
    },

    setUnknownProperty(key, value) {
      const m = meta(this);

      if (m.proto === this || m.isInitializing && m.isInitializing()) {
        // if marked as prototype or object is initializing then just
        // defineProperty rather than delegate
        Ember.defineProperty(this, key, null, value);
        return value;
      }

      const {
        buffer,
        content
      } = Ember.getProperties(this, ['buffer', 'content']);
      let current;
      let previous;

      if (content != null) {
        current = Ember.get(content, key);
      }

      previous = hasOwnProp.call(buffer, key) ? buffer[key] : current;

      if (previous === value) {
        return;
      }

      if (current === value) {
        delete buffer[key];

        if ((0, _helpers.empty)(buffer)) {
          Ember.set(this, 'hasBufferedChanges', false);
        }
      } else {
        buffer[key] = value;
        Ember.set(this, 'hasBufferedChanges', true);
      }

      monkeyPatchedNotifyPropertyChange(this, key);
      return value;
    },

    applyBufferedChanges(onlyTheseKeys) {
      const {
        buffer,
        content
      } = Ember.getProperties(this, ['buffer', 'content']);
      Object.keys(buffer).forEach(key => {
        if (Ember.isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        Ember.set(content, key, buffer[key]);
      });
      this.initializeBuffer(onlyTheseKeys);

      if ((0, _helpers.empty)(Ember.get(this, 'buffer'))) {
        Ember.set(this, 'hasBufferedChanges', false);
      }
    },

    discardBufferedChanges(onlyTheseKeys) {
      const buffer = Ember.get(this, 'buffer');
      this.initializeBuffer(onlyTheseKeys);
      Object.keys(buffer).forEach(key => {
        if (Ember.isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        monkeyPatchedNotifyPropertyChange(this, key);
      });

      if ((0, _helpers.empty)(Ember.get(this, 'buffer'))) {
        Ember.set(this, 'hasBufferedChanges', false);
      }
    },

    /*
     * Determines if a given key has changed else returns false. Allows individual key lookups where
     * as hasBufferedChanged only looks at the whole buffer.
     */
    hasChanged(key) {
      const {
        buffer,
        content
      } = Ember.getProperties(this, ['buffer', 'content']);

      if (typeof key !== 'string' || typeof Ember.get(buffer, key) === 'undefined') {
        return false;
      }

      if (Ember.get(buffer, key) !== Ember.get(content, key)) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});